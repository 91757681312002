const letterA = 'a'.codePointAt(0) as number
const regionalIndicatorA = '🇦'.codePointAt(0) as number

export function getFlagEmoji(code: string) {
  return code
    .toLowerCase()
    .split('')
    .map((char: string) =>
      String.fromCodePoint((char.codePointAt(0) as number) - letterA + regionalIndicatorA)
    )
    .join('')
}

export function removeAccents(str: string) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export function countSpaces(text: string) {
  const spaces = text.match(/(\s+)/g)
  return spaces ? spaces.length : 0
}

import type { App } from 'vue'

import { captureSentryException } from '@/sentry'
import type { Address, AddressResult } from '@/types'

export abstract class AddressCompleter {
  private abortController: AbortController | null = null

  protected cachedResults = new Map<string, AddressResult[]>()
  protected currentResults = new Map<string, AddressResult>()

  constructor(
    protected url: string,
    protected apiKey: string
  ) {}

  protected abstract fetchResults(
    query: string,
    signal: AbortSignal
  ): Promise<AddressResult[] | null>

  async searchAddresses(query: string) {
    this.abortController?.abort('abort: new query')
    this.abortController = new AbortController()

    const cachedResults = this.cachedResults.get(query)
    const results = cachedResults || (await this.fetchResults(query, this.abortController.signal))

    if (results) {
      this.cachedResults.set(query, results)
      this.currentResults = new Map(results.map((result) => [result.id, result]))
    }

    return results
  }

  getAddress(id: string): Address | null {
    return this.currentResults.get(id)?.address
  }
}

export class GeoapifyAddressCompleter extends AddressCompleter {
  protected async fetchResults(
    query: string,
    signal: AbortSignal
  ): Promise<AddressResult[] | null> {
    const options = { method: 'GET', signal }
    const queryParams = new URLSearchParams({
      text: query,
      format: 'json',
      apiKey: this.apiKey || '',
    })

    try {
      const rawResponse = await fetch(this.url + '?' + queryParams.toString(), options)
      const response: { results: Record<string, any>[] } = await rawResponse.json()

      return response.results.map((searchResult) => ({
        id: searchResult.place_id,
        formatted: searchResult.formatted,
        address: {
          address1: searchResult.result_type === 'building' ? searchResult.address_line1 : '',
          zip: searchResult.postcode || '',
          city: searchResult.city || '',
          country: searchResult.country_code?.toUpperCase() || '',
        },
      }))
    } catch (error) {
      if (error !== 'abort: new query') {
        captureSentryException(error)
      }

      return null
    }
  }
}

const adapters = { GeoapifyAddressCompleter }

export const addressCompletionPlugin = {
  install: async (app: App, config: { adapter: string; url: string; apiKey: string } | null) => {
    if (config && config.adapter in adapters) {
      const Adapter = adapters[config.adapter as keyof typeof adapters]
      app.provide('addressCompleter', new Adapter(config.url, config.apiKey))
    } else {
      app.provide('addressCompleter', null)
    }
  },
}

<template>
  <div
    :id="id"
    ref="modal"
    class="modal"
    :class="[...(modalClasses || []), $props.withCloseBtn ? 'with-close-btn' : '']"
    @click.self="onBackdropClickHandler"
  >
    <div class="modal-dialog modal-dialog-centered" :class="modalSizeClass">
      <div class="modal-content rounded-5 d-block">
        <div
          v-if="$slots.header"
          class="modal-header px-4 rounded-top-5"
          :class="modalHeaderClasses"
        >
          <slot name="header" />

          <button
            v-if="withCloseBtn"
            class="btn-close"
            :aria-label="t('modal.close')"
            @click.prevent="hide"
          >
            <IconCross />
          </button>
        </div>
        <div class="modal-body p-4" :class="modalBodyClasses">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Modal } from 'bootstrap'
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import IconCross from '@/components/icons/IconCross.vue'

const props = defineProps<{
  id: string
  modalClasses?: string[]
  modalHeaderClasses?: string[]
  modalBodyClasses?: string[]
  size?: 'sm' | 'md' | 'lg' | 'xl'
  hideOnBackdropClick?: boolean
  withCloseBtn?: boolean
}>()

const { t } = useI18n()

const modal = ref<HTMLElement | null>(null) // The DOM element
const $modal = ref<Modal | null>(null) // The bootstrap element

const modalSizeClass = computed(() => `modal-${props.size || 'md'}`)

onMounted(() => {
  if (window.bootstrap) {
    $modal.value = new window.bootstrap.Modal(modal.value as HTMLElement, { backdrop: false })
  }
})

const show = () => $modal.value?.show()
const hide = () => $modal.value?.hide()

function onBackdropClickHandler() {
  if (props.hideOnBackdropClick) {
    hide()
  }
}

defineExpose({ show, hide })
</script>

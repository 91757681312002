export const getFormattedAmount = (
  amount: number,
  language: string,
  currency: string,
  minDigits: number = 0,
  maxDigits: number = 0
) =>
  new Intl.NumberFormat(language, {
    style: 'currency',
    currency,
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
    currencyDisplay: 'narrowSymbol',
  }).format(amount)

export const getCurrencySymbol = (language: string, currency: string) =>
  getFormattedAmount(0, language, currency).replace('0', '').trim()

export const isCurrencySymbolLeft = (language: string, currency: string) =>
  getFormattedAmount(0, language, currency).charAt(0) !== '0'

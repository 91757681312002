<template>
  <div class="tax-reduction-info">
    <template v-if="organizationCountryCode === 'BE'">
      <div>
        <p class="mb-2 fw-bold">{{ t('be_individual_title') }}</p>
        {{ t('be_individual') }}
      </div>

      <div v-if="isCompanyDonationEnabled" class="mt-3">
        <p class="mb-2 fw-bold">{{ t('be_company_title') }}</p>
        {{ t('be_company') }}
      </div>
    </template>

    <template v-else>
      <div>
        <p class="mb-2 fw-bold">{{ t('income_title') }}</p>
        {{ t(`individual_${organizationType}`) }}
      </div>

      <div v-if="isWealthDonationEnabled" class="mt-3">
        <p class="mb-2 fw-bold">{{ t('wealth_title') }}</p>
        {{ t('wealth_all') }}
      </div>

      <div v-if="isCompanyDonationEnabled" class="mt-3">
        <p class="mb-2 fw-bold">{{ t('company_title') }}</p>
        <span v-if="organizationType === 'assistance'">{{ t('company_assistance') }}</span>
        <span v-else>{{ t('company_generic_cultural') }}</span>
        <span>{{ t('company_all') }}</span>
      </div>
    </template>
    <p class="mt-3">
      <em>{{ t('consult_regulation') }}</em>
    </p>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import type { OrganizationType } from '@/types'

defineProps<{
  organizationType: OrganizationType
  organizationCountryCode: string
  isWealthDonationEnabled: boolean
  isCompanyDonationEnabled: boolean
}>()

const { t } = useI18n()
</script>

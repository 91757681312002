import { captureSentryException } from '@/sentry'
import type { Contact } from '@/types'

const EXTERNAL_CONTACTS_API_ROUTE = `/api/external-contacts`

export class APIError extends Error {
  response: Response

  constructor(response: Response) {
    super('Unexpected response from external-contacts API')
    this.response = response
  }
}

export async function getContactPrefillData(
  apiBaseUrl: string,
  contactKey: string,
  campaignId: string
): Promise<Contact | null> {
  /* Fetch contact data from external-contacts API */

  const url = encodeURI(
    `${apiBaseUrl}${EXTERNAL_CONTACTS_API_ROUTE}/${contactKey}/?campaign=${campaignId}`
  )

  const response = await fetch(url)

  if (response.ok) {
    return await response.json()
  }

  if (response.status !== 404) {
    captureSentryException(new APIError(response))
  }

  return null
}

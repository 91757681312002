<!-- A component handling a single checkbox -->
<template>
  <div
    class="form-check form-switch col py-2 d-flex align-items-center"
    :class="{
      'was-validated': validated,
    }"
  >
    <input
      :id="`id_${name}`"
      v-bind="$attrs"
      ref="el"
      :name="name"
      class="form-check-input"
      type="checkbox"
      role="switch"
      :required="required"
      :checked="modelValue"
      @change="$emit('update:modelValue', ($event.target as HTMLInputElement).checked)"
      @blur="onBlur"
      @focus="validated = false"
    />
    <!-- eslint-disable vue/no-v-html -->
    <label class="form-check-label ms-2" :for="`id_${name}`" v-html="label" />
    <!-- eslint-enable -->
  </div>
  <div class="w-100"></div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const props = withDefaults(
  defineProps<{
    required: boolean
    name: string
    type?: string
    label?: string
    class?: string
    modelValue?: any
  }>(),
  {
    type: 'text',
    class: 'form-control mb-1',
    modelValue: false,
    label: '',
  }
)
defineEmits<{
  (e: 'update:modelValue', modelValue: any): void
}>()

const validated = ref(false)
const el = ref<HTMLInputElement | null>(null)

function onBlur() {
  validated.value = props.required && !el.value?.checked
}
</script>

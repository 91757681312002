<template>
  <div :class="[props.class || 'mb-2 col w-100']">
    <VueSelect
      :model-value="modelValue"
      :placeholder="`${label}${required ? ' *' : ''}`"
      :aria-label="label"
      :required="required"
      :autocomplete="$attrs.autocomplete"
      v-bind="$attrs"
      :class="{
        'missing-data': missingData,
        'valid-data': !!modelValue,
      }"
      :multiple="type === 'multiselect'"
      :options="formattedOptions"
      :get-option-label="(option: Option) => option.title"
      :selectable="(o: Option) => !!o.value"
      :reduce="(o: Option) => o.value"
      :filter-by="filterFunction"
      append-to-body
      @update:model-value="$emit('update:modelValue', $event || null)"
      @search="onSearch"
      @search:blur="onBlur()"
      @search:focus="missingData = false"
    >
      <template #search="{ attributes, events }">
        <input
          class="vs__search"
          :required="required && !modelValue"
          v-bind="attributes as object"
          v-on="events"
        />
      </template>
      <template #no-options>
        {{ t('no_matching_option') }}
      </template>
      <template #option="{ title, subtitle }">
        <FormOption :title="title" :subtitle="subtitle" />
      </template>
    </VueSelect>
  </div>
  <!-- Vue select does not send any data on submit so use hidden input for that -->
  <template v-if="Array.isArray(modelValue)">
    <input v-for="val in modelValue" :key="val" type="hidden" :name="name" :value="val" />
  </template>
  <input v-else type="hidden" :value="modelValue" :name="name" />
  <div v-if="!props.class" class="w-100"></div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import VueSelect from 'vue-select'

import FormOption from './_FormOption.vue'

import { removeAccents } from '@/utils/text'

interface Option {
  title: string
  subtitle: string
  value: any
}

const props = defineProps<{
  required: boolean
  label: string
  type: 'multiselect' | 'dropdown'
  choices: [string, string][]
  modelValue?: any
  class?: string
  name: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: string): void
}>()

const { t } = useI18n()

const missingData = ref(false)

const formattedOptions = computed<Option[]>(() =>
  props.choices.map(([oValue, oLabel]) => {
    const [title, ...rest] = oLabel.split('|')
    return { value: oValue, title: title, subtitle: (rest || []).join('|') }
  })
)

function onBlur() {
  missingData.value = props.required && !props.modelValue
}

function filterFunction(_: any, optionLabel: string, search: string) {
  // Override default filter function to remove accents and search into title and subtitle
  const option = formattedOptions.value.find(({ title }) => title === optionLabel)
  return (
    option &&
    removeAccents(`${option.title} ${option.subtitle}` || '')
      .toLocaleLowerCase()
      .indexOf(removeAccents(search).toLocaleLowerCase()) > -1
  )
}

function onSearch(search: string) {
  // This is called on autocomplete, if the search matches one of the options, fire the update event
  // will work on Chrome-like but not in FF
  if (search && props.choices.filter((o: [string, string]) => o.includes(search)).length) {
    emit('update:modelValue', search)
  }
}
</script>

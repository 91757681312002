<script setup lang="ts">
import { computed, inject, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import GenericModal from '@/components/GenericModal.vue'
import type { EquivalenceGrid, PaymentType } from '@/types'
import { getFormattedAmount } from '@/utils/currency'

const props = defineProps<{
  grid: EquivalenceGrid
  paymentType: PaymentType
  modelValue?: number
  indexToFocus: number | null
  onTouchStart?: () => void
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: number): void
  (e: 'update:keyboardFocusedAmount', amount: number): void
  (e: 'touchstart', event: TouchEvent): void
}>()

const language = inject('language') as string
const currency = inject('currency') as string

const { t } = useI18n()

const carousel = ref<HTMLDivElement>()
const detailModals = new Array(props.grid.equivalences.length)

const carouselTransformStyle = computed(() => {
  // Size of all previous cards + size of the gap between them
  const translateX = `translateX(calc(-${props.indexToFocus} * var(--equivalence-card-width) - ${props.indexToFocus}em))`
  return { transform: translateX }
})

function selectEquivalence(amount: number) {
  emit('update:modelValue', amount)
}

function onKeyboardFocusOnCard(amount: number) {
  emit('update:keyboardFocusedAmount', amount)
  carousel.value?.parentElement?.scroll(0, 0)
}

function onBlurFromCard() {
  if (!carousel.value?.contains(document.activeElement)) {
    emit('update:keyboardFocusedAmount', 0)
  }
}

function openDetails(equivalenceIndex: number) {
  detailModals[equivalenceIndex].show()
}

function selectEquivalenceFromDetails(equivalenceIndex: number) {
  detailModals[equivalenceIndex].hide()
  selectEquivalence(props.grid.equivalences[equivalenceIndex].amount)
  onBlurFromCard()
}
</script>

<template>
  <div
    ref="carousel"
    class="equivalence-carousel"
    :class="{ 'opacity-50': modelValue === 0 }"
    :style="carouselTransformStyle"
    @touchstart="emit('touchstart', $event)"
  >
    <div
      v-for="(equivalence, index) in grid.equivalences"
      :key="equivalence.amount"
      class="card equivalence-card"
      :class="{
        selected: equivalence.amount === modelValue,
        'equivalence-card-with-image': grid.format === 'image',
        'equivalence-card-text-only': grid.format === 'text',
      }"
      :aria-current="equivalence.amount === modelValue"
      :aria-labelledby="`equivalence-text-${equivalence.amount}-${paymentType} btn-${equivalence.amount}-${paymentType}`"
      tabindex="0"
      @focus="onKeyboardFocusOnCard(equivalence.amount)"
      @blur="onBlurFromCard"
      @mousedown="selectEquivalence(equivalence.amount)"
      @keydown.enter.space.prevent="selectEquivalence(equivalence.amount)"
    >
      <div class="card-body">
        <img
          v-if="grid.format === 'image'"
          :src="equivalence.image_url"
          class="card-img-top"
          :alt="equivalence.image_alt"
        />
        <div v-else class="card-title">
          {{ getFormattedAmount(equivalence.amount, language, currency) }}
        </div>
        <div :id="`equivalence-text-${equivalence.amount}-${paymentType}`" class="card-subtitle">
          {{ equivalence.description }}
        </div>

        <button
          v-if="equivalence.detail"
          type="button"
          class="btn btn-link"
          @click.prevent="openDetails(index)"
          @keydown.enter.space.stop.prevent="openDetails(index)"
          @focus="onKeyboardFocusOnCard(equivalence.amount)"
        >
          {{ t('amount_chooser.equivalences.learn_more') }}
        </button>
      </div>
    </div>
  </div>

  <template v-for="(equivalence, index) in grid.equivalences" :key="equivalence.amount">
    <GenericModal
      v-if="equivalence.detail"
      :id="`equivalence-detail-modal-${equivalence.amount}-${paymentType}`"
      :ref="(el) => (detailModals[index] = el)"
      :modal-classes="['fade', 'modal-blur', 'equivalence-detail-modal']"
      :modal-body-classes="['px-5']"
      hide-on-backdrop-click
      with-close-btn
      size="lg"
      :aria-labelledby="`equivalence-detail-modal-${equivalence.amount}-${paymentType}-title`"
    >
      <template #header>
        <h4
          :id="`equivalence-detail-modal-${equivalence.amount}-${paymentType}-title`"
          class="my-2 w-100 text-center"
        >
          {{ equivalence.description }}
        </h4>
      </template>
      <div class="equivalence-detail-amount-container">
        <div class="equivalence-detail-amount">
          {{ getFormattedAmount(equivalence.amount, language, currency) }}
        </div>
      </div>
      <img
        v-if="grid.format === 'image'"
        :src="equivalence.image_url"
        :alt="equivalence.image_alt"
        class="equivalence-detail-img"
      />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="equivalence.detail" />

      <button
        type="button"
        class="btn btn-primary rounded-main"
        @click="selectEquivalenceFromDetails(index)"
        @keyup.enter.space.prevent="selectEquivalenceFromDetails(index)"
      >
        <span class="font-heading text-uppercase equivalence-detail-btn">
          {{ t(`amount_chooser.equivalences.choose`) }}
        </span>
      </button>
    </GenericModal>
  </template>
</template>

<script setup lang="ts">
import { inject } from 'vue'
import { useI18n } from 'vue-i18n'

import { getFormattedAmount } from '@/utils/currency'

defineProps<{
  amount: number
  selected: boolean
}>()

const language = inject('language') as string
const currency = inject('currency') as string
const { t } = useI18n()
</script>

<template>
  <div class="single-amount-card mx-4" tabindex="0" :aria-selected="selected">
    <div class="single-amount-label">{{ t(`amount_chooser.single_amount.amount`) }}</div>
    <div class="single-amount-amount">
      {{ getFormattedAmount(amount, language, currency) }}
    </div>
  </div>
</template>

import type { App } from 'vue'

import type { TagManagerDefaults } from '@/types'

export class TagManager {
  constructor(public defaults: TagManagerDefaults) {}

  sendAnalytics(data: object): void {
    window.dataLayer.push(data)
  }
}

export const tagManagerPlugin = {
  install(
    app: App,
    { tagManager, defaults }: { tagManager: boolean; defaults: TagManagerDefaults }
  ) {
    if (tagManager) {
      app.provide('tagManager', new TagManager(defaults))
    } else {
      app.provide('tagManager', null)
    }
  },
}

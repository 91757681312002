import type { AddonField } from '@/types'

export function getAddonField(addons: AddonField[], addonName: string): AddonField | null {
  return addons.find((el) => el.name === addonName) || null
}

export function getAddonChoice(addonField: AddonField, value: boolean | string | number) {
  return (addonField.choices || []).find(([addonValue]) => addonValue === value)
}

export function getAddonAmount(
  addons: AddonField[],
  addonName: string,
  addonValue?: boolean | string | number
): number {
  const addonField = getAddonField(addons, addonName)
  if (!addonField) {
    return 0
  }

  if (addonField.type === 'boolean') {
    return (addonValue as boolean) ? (addonField.amount as number) : 0
  }

  if (addonField.type === 'number') {
    return parseInt(addonValue as string) || 0
  }

  if (addonField.type === 'dropdown') {
    const selectedChoice = getAddonChoice(addonField, addonValue as string)
    return (selectedChoice && selectedChoice[1].amount) || 0
  }

  return 0
}

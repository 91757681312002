import type { FieldConfig, PaymentType } from '@/types'

/**
 * Sort the field config array
 * @param arr
 * @param paymentType
 */
export function sortedByWeight(arr: FieldConfig[], paymentType: PaymentType) {
  return arr
    .filter((f: FieldConfig) =>
      paymentType === 'one-off'
        ? ['contact', 'payment', 'payment_commitment'].includes(f.attached_to)
        : ['contact', 'commitment', 'payment_commitment'].includes(f.attached_to)
    )
    .sort((a: FieldConfig, b: FieldConfig) => a.weight - b.weight)
}

/**
 * Omit a list of properties from an object
 */
export function omit<T extends object, K extends keyof T>(obj: T, ...keys: K[]): Omit<T, K> {
  const newObj = { ...obj }
  keys.forEach((key) => delete newObj[key])
  return newObj
}

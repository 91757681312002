<template>
  <div v-if="type === 'amount'" class="input-group">
    <div :class="extraClass || {}">
      <slot />
    </div>
    <span class="input-group-text">{{ currencySymbol }}</span>
  </div>

  <div v-else :class="extraClass || {}">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { inject } from 'vue'

import type { FieldType } from '@/types'
import { getCurrencySymbol } from '@/utils/currency'

const language = inject('language') as string
const currency = inject('currency') as string
const currencySymbol = getCurrencySymbol(language, currency)

defineProps<{
  type: FieldType
  extraClass?: any
}>()
</script>

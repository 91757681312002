<template>
  <div v-if="widgetOptions" class="pb-4 mx-4">
    <div
      class="heart-container position-relative overflow-hidden d-flex align-items-center justify-content-center pb-5"
    >
      <div class="heart-red-circles" />
      <div class="heart-beating-container py-4">
        <i class="heart" />
      </div>
    </div>

    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="text-center mb-4" v-html="text" />

    <button
      class="position-relative btn btn-tertiary w-100 rounded-main my-2 d-flex align-items-center justify-content-center"
      type="button"
      @click="selectAmount(amount1)"
    >
      <div class="btn-heart-container">
        <i class="heart" style="--heart-size: 1.5em" />
      </div>
      <strong class="font-heading me-2 text-uppercase">{{
        t(`push_regular.pay.${paymentTerminology}`)
      }}</strong>
      {{
        t('push_regular.amount_per_month', {
          amount: getFormattedAmount(amount1, language, currency),
        })
      }}
    </button>

    <button
      v-if="amount2 && amount1 !== amount2"
      class="btn btn-primary rounded-main w-100 my-2 d-flex align-items-center justify-content-center"
      type="button"
      @click="selectAmount(amount2)"
    >
      <strong class="font-heading me-2 text-uppercase">{{
        t(`push_regular.pay.${paymentTerminology}`)
      }}</strong>
      {{
        t('push_regular.amount_per_month', {
          amount: getFormattedAmount(amount2, language, currency),
        })
      }}
    </button>

    <button
      class="btn btn-grey rounded-main w-100 my-2 shadow d-flex align-items-center justify-content-center"
      type="button"
      @click="emit('validate')"
    >
      <i18n-t keypath="push_regular.keep_payment">
        <template #stick_with>
          <strong class="font-heading text-uppercase me-2">{{
            t(`push_regular.stick_with.${paymentTerminology}`)
          }}</strong>
        </template>
        <template #amount>
          {{ getFormattedAmount(baseAmount, language, currency) }}
        </template>
      </i18n-t>
    </button>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, inject, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useStore, useWidgetsStore } from '@/store'
import type { PaymentTerminology, PushRegularWidgetOptions } from '@/types'
import { getFormattedAmount } from '@/utils/currency'

const { t } = useI18n()
const emit = defineEmits<{
  (e: 'validate'): void
  (e: 'cancel'): void
}>()

const { getWidgetOptions, isWidgetEnabled } = useWidgetsStore()
const { baseAmount, customAmount, originalOneOffAmount, currentType, desiredStep } =
  storeToRefs(useStore())
const { resetAmountSelection } = useStore()

// Config injected from backend
const language = inject('language') as string
const currency = inject('currency') as string
const paymentTerminology = inject<PaymentTerminology>('paymentTerminology')
const minimumRegularAmount = inject('minimumAmountRegular') as number
const maximumRegularAmount = inject('maximumAmountRegular') as number

const widgetOptions = ref<PushRegularWidgetOptions>(null)
const amount1 = computed(() =>
  widgetOptions.value ? calculateAmount(widgetOptions.value.amount1_percent) : 0
)
const amount2 = computed(() =>
  widgetOptions.value && widgetOptions.value.amount2_percent
    ? calculateAmount(widgetOptions.value.amount2_percent)
    : 0
)

const text = computed(() =>
  widgetOptions.value
    ? widgetOptions.value.text.replace(
        new RegExp('{amount}', 'g'),
        getFormattedAmount(baseAmount.value, language, currency)
      )
    : null
)

function calculateAmount(percent: number) {
  // Suggested amount should be between minimum and maximum regular amounts
  const amount = Math.ceil((percent * baseAmount.value) / 100)
  return Math.min(Math.max(minimumRegularAmount, amount), maximumRegularAmount)
}

function selectAmount(selectedAmount: number) {
  originalOneOffAmount.value = baseAmount.value
  customAmount.value = selectedAmount
  resetAmountSelection()
  currentType.value = 'regular'

  emit('validate')
}

onMounted(() => {
  if (desiredStep.value) {
    // Go back to previous step to replay step
    desiredStep.value = undefined
    emit('cancel')
  } else if (isWidgetEnabled('pushRegular')) {
    widgetOptions.value = getWidgetOptions('pushRegular') as PushRegularWidgetOptions
  }
})
</script>

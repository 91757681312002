export const throttled = (func: Function, delayInMs: number): Function => {
  let lastCall: ReturnType<typeof setTimeout>
  let lastRan: number = Date.now() - (delayInMs + 1) // Enforce a past value on first run to directly call
  return function (...args: any[]) {
    clearTimeout(lastCall)

    return new Promise((resolve) => {
      lastCall = setTimeout(
        function () {
          resolve(func(...args))
          lastRan = Date.now()
        },
        delayInMs - (Date.now() - lastRan)
      )
    })
  }
}

export const isElementVisible = (element: HTMLElement, visibleRatio = 0.75): boolean => {
  if (!element || element.nodeType !== Node.ELEMENT_NODE) {
    return false
  }

  const html = document.documentElement
  const elementBoundingRect = element.getBoundingClientRect()

  // Element is not visible at all
  if (
    !elementBoundingRect ||
    elementBoundingRect.bottom < 0 ||
    elementBoundingRect.right < 0 ||
    elementBoundingRect.left > html.clientWidth ||
    elementBoundingRect.top > html.clientHeight
  ) {
    return false
  }

  // Calculate visible ratio of the element
  const elementArea = elementBoundingRect.width * elementBoundingRect.height
  const elementVisibleArea =
    (Math.min(elementBoundingRect.right, html.clientWidth) -
      Math.max(elementBoundingRect.left, 0)) *
    (Math.min(elementBoundingRect.bottom, html.clientHeight) - Math.max(elementBoundingRect.top, 0))

  return elementVisibleArea / elementArea >= visibleRatio
}

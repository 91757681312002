import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import { createApp, ref } from 'vue'
import { createI18n } from 'vue-i18n'

import DonationForm from './DonationForm.vue'
import './assets/bootstrap.scss'

import { addressCompletionPlugin } from '@/plugins/address-completion'
import { emailVerificationPlugin } from '@/plugins/email-verification'
import { tagManagerPlugin } from '@/plugins/tag-manager'
import urlExtractor from '@/url_extractor'

// Inject configuration
const config = JSON.parse(
  (document.getElementById('campaign-data') as HTMLScriptElement).textContent as string
) as Record<string, any>
if (import.meta.env.DEV) {
  console.info('Injected config', config)
}
const app = createApp(DonationForm)
const { tagManager, tagManagerDefaults, ...providedConfig } = config
Object.entries(providedConfig).forEach(([key, value]) => {
  app.provide(key, value)
})

// i18n
// Translations are extracted from config and are already in the page language
// We rebuild a messages object as expected by i18n module
const locale = document.documentElement.lang || 'en'
const messages = { [locale]: config.messages }

const i18n = createI18n({
  locale: locale,
  legacy: false,
  messages,
})

const pinia = createPinia()
  .use(({ store }) => {
    // Handle expiration as a global behavior for each store
    const item = localStorage.getItem(store.$id)
    const value = item && JSON.parse(item)
    if (
      value &&
      value.updatedAt &&
      Date.parse(value.updatedAt) < Date.parse(config.expireIfUpdatedBefore)
    ) {
      if (import.meta.env.DEV) {
        console.info(`Resetting '${store.$id}' store because it has expired`)
      }
      localStorage.removeItem(store.$id)
    }
    store.$state.updatedAt = ref(new Date())

    store.$subscribe(
      (mutation) => {
        // When store changes, update store's updateAt
        if (
          store.$id === mutation.storeId &&
          !(mutation.type === 'patch object' && mutation.payload.updatedAt)
        ) {
          if (import.meta.env.DEV) {
            console.info(`Updating '${store.$id}' store expiry time due to change`, mutation)
          }
          // @ts-expect-error TS2769 Because it's not from the initial state
          store.$patch({ updatedAt: new Date() })
        }
      },
      // "sync" is required to properly work with afterRestore hooks, see https://github.com/vuejs/pinia/issues/992
      { flush: 'sync' }
    )
  })
  .use(
    createPersistedState({
      key: (id) => {
        // Dynamic store key can be provided by backend
        return {
          [id]: id,
          store: config.storeCookieName,
          basket: config.basketCookieName,
        }[id]
      },
    })
  )
  .use(urlExtractor([localStorage]))

app
  .use(tagManagerPlugin, { tagManager, defaults: tagManagerDefaults })
  // .use(sentry, { dsn: sentryDsn, environment: sentryEnvironment })
  .use(pinia)
  .use(i18n)
  .use(emailVerificationPlugin, config.widgets.emailVerification)
  .use(addressCompletionPlugin, config.widgets.addressCompletion)
  .mount('#donation-form')

import isEmail from 'validator/es/lib/isEmail'

/**
 * These validation methods can be used on FormInput component via the `validators` attribute.
 * Example:
 * import { validateBERegistrationNumber } from '@/utils/input-validators'
 * <FormInput validators=[validateBERegistrationNumber] />
 */

export function validateEmail(value: string, translate: Function) {
  /**
   * Validate email address
   *
   * To be as close as possible to NORBr validator, use same validation lib.
   */
  if (!value) {
    return
  }

  if (!isEmail(value)) {
    return translate('validators.invalid_email')
  }
}

export function validatePhoneNumber(value: string, translate: Function) {
  /**
   * Validate phone number
   *
   * To be as close as possible to NORBr validator, a phone number
   * must have at least 7 digits.
   */

  if (!value) {
    return
  }

  // Only keep digits for validation
  const cleanedValue = value.replace('+', '00').replace(/\D/g, '')

  if (cleanedValue.length < 7 || value.length > 32) {
    return translate('validators.invalid_phone_number')
  }
}

export function validateSiren(value: string, translate: Function) {
  /**
   * Validate SIREN number
   *
   * @see https://portal.hardis-group.com/doccenter/pages/viewpage.action?pageId=120357227
   */
  if (!value) {
    return
  }

  // Only keep digits for validation
  const cleanedValue = value.replace(/\D/g, '')

  if (cleanedValue.length !== 9) {
    return translate('validators.invalid_siren')
  }

  let total = 0
  for (let i = cleanedValue.length - 1; i >= 0; i--) {
    const digit = Number(cleanedValue[i]) * (i % 2 === 0 ? 1 : 2)
    total += digit > 9 ? digit - 9 : digit
  }

  if (total % 10 !== 0) {
    return translate('validators.invalid_siren')
  }
}

export function validateBERegistrationNumber(value: string, translate: Function) {
  /**
   * Validate BE Registration number https://fr.wikipedia.org/wiki/Num%C3%A9ro_de_registre_national
   *
   * Valid format is YYMMDDSSSXX
   * - YY is the year of birth
   * - MM is the month of birth
   * - DD is the day of birth
   * - SSS is the counter of daily births (not validated)
   * - XX is the mod 97 validator
   */

  if (!value) {
    return
  }

  const errorMessage = translate('validators.invalid_registration_number')

  // Check that value only contains numbers, dashes, points and spaces
  if (!value.match(/^[0-9\-\\. ]+$/)) {
    return errorMessage
  }

  // Only keep digits for validation
  const cleanedValue = value.replace(/\D/g, '')

  // Check if 11 numeric characters
  if (!new RegExp('[0-9]{11}').test(cleanedValue)) {
    return errorMessage
  }

  // Check if valid birthdate
  const YY = parseInt(cleanedValue.substring(0, 2))
  const MM = parseInt(cleanedValue.substring(2, 4))
  const DD = parseInt(cleanedValue.substring(4, 6))
  if (new Date(`19${YY}-${MM}-${DD}`).toString() === 'Invalid Date') {
    return errorMessage
  }

  // Check mod 97 validator
  const XX = parseInt(cleanedValue.substring(9))
  const number = parseInt(cleanedValue.substring(0, 9))
  const mod97before2000 = 97 - (number % 97)
  const mod97after2000 = 97 - (parseInt(`2${number}`) % 97)
  if (XX !== mod97before2000 && mod97after2000 !== XX) {
    return errorMessage
  }
}

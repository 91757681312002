const ibanFormatMapping: { [key: string]: string } = {
  BE: 'BE00 0000 0000 0000',
  CH: 'CH00 0000 0000 0000 0000 0',
  DE: 'DE00 0000 0000 0000 0000 00',
  FR: 'FR00 0000 0000 0000 0000 0000 000',
  LU: 'LU00 0000 0000 0000 0000',
  NL: 'NL00 0000 0000 0000 00',
}

const bicFormat = 'XXXX {countryCode}XX XXX'
const bicFormatCountries = Object.keys(ibanFormatMapping)

export function getIbanFormat(contactCountryCode: string, organizationCountryCode: string): string {
  return (
    ibanFormatMapping[contactCountryCode] ||
    ibanFormatMapping[organizationCountryCode] ||
    ibanFormatMapping.FR
  )
}

export function getBicFormat(contactCountryCode: string, organizationCountryCode: string): string {
  const countryCode = bicFormatCountries.includes(contactCountryCode)
    ? contactCountryCode
    : bicFormatCountries.includes(organizationCountryCode)
      ? organizationCountryCode
      : 'FR'
  return bicFormat.replace('{countryCode}', countryCode)
}

// import type { App } from 'vue'

// let _isSentryEnabled = false

// type SentryConfig = {
//   dsn: string
//   environment: 'dev' | 'demo' | 'production'
// }

export function captureSentryException(e: any) {
  // Only capture exceptions if Sentry is enabled
  // if (_isSentryEnabled) {
  if (window.Sentry) {
    window.Sentry.captureException(e, { extra: e.data || null })
  }
}

export default {
  // install(app: App, config: SentryConfig) {
  //   if (config.dsn) {
  //     _isSentryEnabled = true
  //     // Initialize Sentry
  //     window.Sentry.init({
  //       app,
  //       dsn: config.dsn,
  //       environment: config.environment,
  //       integrations: [new window.Sentry.BrowserTracing()],
  //
  //       // Set tracesSampleRate to 1.0 to capture 100%
  //       // of transactions for performance monitoring.
  //       // We recommend adjusting this value in production
  //       tracesSampleRate: 1.0,
  //
  //       // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  //       // tracePropagationTargets: [],
  //     })
  //   }
  // },
}

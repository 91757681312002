import type {
  CheckoutRequestData,
  CheckoutResponseData,
  ConfirmIbanRequestData,
  OrderRequestData,
  OrderResponseData,
  PledgeResponseData,
  ResponseData,
  SendChequePledgeRequestData,
} from '@/types'

export const RGIVE_PARTNER = 'RGIVE'
export const API_URL_MAPPING = {
  checkout: {
    'one-off': '/api/checkout-oneoff/',
    regular: '/api/checkout-regular/',
  },
  order: {
    'one-off': '/api/order-oneoff/',
    regular: '/api/order-regular/',
  },
  confirmIban: '/api/confirm-iban/',
  sendPledge: '/api/pledge/',
}

export class NorbrError extends Error {
  data: any
  response: Response

  constructor(message: string, data: any, response: Response) {
    super(message)
    this.data = data
    this.response = response
  }
}

export class RGiveBadRequestError extends Error {
  sourceError: any
  fields: string[]

  constructor(data: any) {
    super('Invalid values')
    this.sourceError = data

    let fields: string[] = []

    if (data.contact) {
      fields = fields.concat(Object.keys(data.contact))
      delete data.contact
    }
    if (data.custom_data) {
      fields = fields.concat(Object.keys(data.custom_data))
      delete data.custom_data
    }
    if (data.addon_data) {
      fields = fields.concat(Object.keys(data.addon_data))
      delete data.addon_data
    }

    this.fields = fields.concat(Object.keys(data))
  }
}

const doRequest = async <T extends ResponseData>(
  requestData: { [key: string]: any },
  url: string,
  headers?: { [key: string]: any }
): Promise<T> => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify(requestData),
  })

  const data = response.headers.get('content-type')?.includes('application/json')
    ? await response.json()
    : {}

  if (response.ok) {
    return data
  }

  if (response.status === 400) {
    throw new RGiveBadRequestError(data)
  }

  throw new NorbrError(data && data.error, data, response)
}

export const callCheckout = (requestData: CheckoutRequestData, url: string) => {
  return doRequest<CheckoutResponseData>(requestData, url)
}

export const callOrder = (requestData: OrderRequestData, url: string) => {
  return doRequest<OrderResponseData>(requestData, url)
}

export const callConfirmIban = (requestData: ConfirmIbanRequestData, url: string) => {
  return doRequest<OrderResponseData>(requestData, url)
}

export const callSendChequePledge = (requestData: SendChequePledgeRequestData, url: string) => {
  return doRequest<PledgeResponseData>(requestData, url)
}

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, inject, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import FormFieldFactory from '@/components/FormFieldFactory.vue'
import LegalInformation from '@/components/LegalInformation.vue'
import { AddressCompleter } from '@/plugins/address-completion'
import { useContactStore, useStore, useWidgetsStore } from '@/store'
import type { FieldConfig, PaymentTerminology, Section } from '@/types'
import { getFormattedAmount } from '@/utils/currency'
import { attachFieldsExtraConfig } from '@/utils/form-fields'
import { omit, sortedByWeight } from '@/utils/objects'
import { throttled } from '@/utils/toolbox'

const emit = defineEmits<{
  (e: 'submit'): void
}>()
const { t } = useI18n()

// Config injected from backend
const language = inject('language') as string
const currency = inject('currency') as string
const paymentTerminology = inject<PaymentTerminology>('paymentTerminology')
const formFieldsDisplay = inject('formFieldsDisplay') as Record<Section, FieldConfig[]>
const displayedContactFieldNames = computed(() =>
  formFieldsDisplay['step-2'].map((field) =>
    field.name && field.attached_to === 'contact' && field.display !== 'hidden' ? field.name : null
  )
)

const { contact } = storeToRefs(useContactStore())
const { totalAmount, currentType, desiredStep, initialStep, paymentData, invalidFields } =
  storeToRefs(useStore())
const { isWidgetEnabled } = useWidgetsStore()

const form = ref<HTMLFormElement | null>(null)

onMounted(() => {
  if (desiredStep.value) {
    const formElem = form.value as HTMLFormElement
    // Reset desired step when at tunnel's end or wrong data
    if (desiredStep.value === 'contact' || !formElem.checkValidity()) {
      initialStep.value = desiredStep.value
      desiredStep.value = undefined
    } else {
      formElem.requestSubmit()
    }
  }
})

const addressCompleter = inject('addressCompleter') as AddressCompleter | null

async function searchAddresses(value: string) {
  if (!addressCompleter || value.length < 5) {
    return []
  }

  const addressResults = await addressCompleter.searchAddresses(value)

  return addressResults
    ? addressResults.map((addressResult) => ({
        value: addressResult.id,
        label: addressResult.formatted,
      }))
    : []
}

async function applyAddress(id: string) {
  const address = addressCompleter && addressCompleter.getAddress(id)
  if (address) {
    for (const field in address) {
      if (displayedContactFieldNames.value.includes(field)) {
        contact.value[field] = address[field]
      }
    }
  }
}

const searchAddressesThrottled = throttled(searchAddresses, 500)
const contactFormFieldsExtraConfig = ref({
  address1: {
    autocompleteSearch: searchAddressesThrottled,
    autocompleteApply: applyAddress,
  },
})
</script>

<template>
  <div class="py-3 overlay-scroll">
    <form
      ref="form"
      class="mx-4 d-flex justify-content-between flex-column h-100"
      @submit.prevent="emit('submit')"
    >
      <div
        v-if="invalidFields['step-2'].length > 0"
        class="mb-2 norbr-alert border-0 rounded-2 p-2"
      >
        {{ t('invalid_fields', { field_names: invalidFields['step-2'].join(', ') }) }}
      </div>
      <div class="d-flex flex-wrap justify-content-start scroll-shadows">
        <FormFieldFactory
          v-for="config in attachFieldsExtraConfig(
            sortedByWeight(formFieldsDisplay['step-2'] || [], currentType),
            contactFormFieldsExtraConfig
          )"
          v-bind="omit(config, 'weight', 'attached_to')"
          :key="config.name"
          v-model="(config.attached_to === 'contact' ? contact : paymentData)[config.name]"
          :full-model="config.attached_to === 'contact' ? contact : paymentData"
        />
      </div>

      <button
        class="btn btn-primary rounded-main w-100 my-2 shadow align-self-end d-flex justify-content-center"
        type="submit"
      >
        <i18n-t keypath="contact_step.btn_text">
          <template #action>
            <strong class="font-heading text-uppercase me-2">{{
              t(`contact_step.btn_action.${paymentTerminology}`)
            }}</strong>
          </template>
          <template #amount>
            {{ getFormattedAmount(totalAmount, language, currency) }}
          </template>
          <template #type>
            {{ t(`contact_step.btn_type.${currentType}`) }}
          </template>
        </i18n-t>
      </button>

      <LegalInformation v-if="isWidgetEnabled('legalInformation')" />
    </form>
  </div>
</template>

import type { NorbrWidgetBuildData, PaymentType } from '@/types'
import { API_URL_MAPPING, callOrder } from '@/utils/payment'

export const NORBR_TOGGLE_SUBMIT_EVENT = 'rgive-toggle-submit-button'

export declare const Norbr: any

export function dispatchToggleConfirmButtonEvent(active: boolean = true) {
  document.dispatchEvent(
    new CustomEvent(NORBR_TOGGLE_SUBMIT_EVENT, { detail: { buttonActive: active } })
  )
}

export const buildWidget = (
  buildData: NorbrWidgetBuildData,
  paymentType: PaymentType,
  apiBaseUrl: string
): typeof Norbr => {
  const norbr: typeof Norbr = new Norbr({
    templateVersion: 'v1',
    publicapikey: buildData.publicApiKey,
    locale: buildData.language,
    environment: buildData.environment,
    tokentype: buildData.tokenType,
    paymentmethods: JSON.stringify({ payment_methods_available: buildData.paymentMethods }),
    checkoutId: buildData.checkoutId,
    displayButtons: false, // Custom button will be used to submit form (using norbr_submit method)
    displayCardHolder: false,
    toggleButtonEvent: NORBR_TOGGLE_SUBMIT_EVENT,
    onSubmit: () => {
      return callOrder(
        {
          checkout_id: norbr.checkoutId,
          token: norbr.token,
          subscription_id: buildData.subscriptionId,
          payment: (paymentType === 'one-off' && (buildData.reference as string)) || undefined,
          commitment: (paymentType === 'regular' && (buildData.reference as string)) || undefined,
          campaign: buildData.campaignUuid as string,
          payment_method_name: norbr.paymentMethodName,
          component_type: norbr.paymentMethods.payment_methods_available.find(
            (el: any) => el.name === norbr.paymentMethodName
          ).component_type as string,
          contact: buildData.contact,
          payment_data: buildData.paymentData,
        },
        `${apiBaseUrl}${API_URL_MAPPING.order[paymentType]}`
      )
    },
  })

  // Add aria-label attributes to card fields for accessibility
  const { ariaLabels } = buildData
  const selectorsMapping: { [key: string]: string } = {
    cardNumber: '#norbr-card_number',
    expirationDate: '#norbr-card_validity',
    cvc: '#norbr-cvc',
  }
  const $formElement = document.querySelector('#norbr-payment-container-inner_v1')
  if ($formElement) {
    Object.entries(ariaLabels).forEach(([key, label]) => {
      $formElement.querySelector(selectorsMapping[key])?.setAttribute('aria-label', label)
    })
  }
  return norbr
}

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 92.371 92.731">
    <g transform="translate(46.362 0) rotate(45)">
      <path
        d="M63.735,37.2a6.244,6.244,0,0,1-4.418,1.834H39.04V59.3a6.252,6.252,0,0,1-12.5,0V39.034H6.258a6.256,6.256,0,0,1,0-12.512H26.536V6.252a6.252,6.252,0,0,1,12.5,0v20.27H59.318A6.261,6.261,0,0,1,63.735,37.2Z"
        fill="#fff"
      />
    </g>
  </svg>
</template>

<template>
  <svg width="42" height="91" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 91">
    <defs>
      <linearGradient id="a" x1=".192" x2=".77" y2=".851" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#e46c66" />
        <stop offset="1" stop-color="#de3326" />
      </linearGradient>
      <linearGradient id="b" x1=".5" x2=".5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#ee7759" />
        <stop offset="1" stop-color="#edceba" />
      </linearGradient>
      <linearGradient id="c" x1=".5" x2=".5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#f5bb42" />
        <stop offset="1" stop-color="#f38127" />
      </linearGradient>
    </defs>
    <g transform="translate(-27.59 -89.361)">
      <path fill="none" d="M27.59 89.361h42v91h-42z" />
      <path
        d="M97.1 60.452a30.366 30.366 0 0 1-10.068-9.021c-1.96-2.9-2.972-5.993-1.768-8.626a6.627 6.627 0 0 1 2.236-2.672 7.1 7.1 0 0 1 3.35-1.234 7.407 7.407 0 0 1 3.55.523 8.414 8.414 0 0 1 2.832 1.925 8.413 8.413 0 0 1 2.832-1.925 7.406 7.406 0 0 1 3.548-.523 7.1 7.1 0 0 1 3.35 1.234 6.629 6.629 0 0 1 2.243 2.672c1.2 2.634.192 5.731-1.768 8.626a30.352 30.352 0 0 1-10.069 9.023.305.305 0 0 1-.275 0Z"
        transform="rotate(-12.04 462.02 290.878)"
        fill-rule="evenodd"
        fill="url(#a)"
      />
      <circle cx="2.5" cy="2.5" r="2.5" transform="translate(58.59 106.362)" fill="#8dc5fa" />
      <circle cx="2" cy="2" r="2" transform="translate(41.59 171.362)" fill="#f0853e" />
      <circle cx="1.5" cy="1.5" r="1.5" transform="translate(40.59 94.362)" fill="#9ee3ef" />
      <path
        d="m13.923 10-1.387 2.536L10 13.923l2.536 1.387 1.387 2.536 1.387-2.536 2.536-1.387-2.536-1.387Z"
        transform="rotate(-32 278.396 41.487)"
        fill="url(#b)"
      />
      <path
        d="m55.345 148.451-1.977.808-2.102-.379.814 1.97-.38 2.102 1.97-.814 2.097.386-.808-1.976Z"
        fill="#9ee3ef"
      />
      <path
        d="M4.174 0 2.7 2.7 0 4.174 2.7 5.65l1.476 2.7 1.474-2.7 2.7-1.476L5.65 2.7Z"
        transform="rotate(76 -48.07 77.494)"
        fill="url(#c)"
      />
      <path fill="#8dc5fa" d="m32.104 136.606 3.429-2.06 1.545 2.571-3.429 2.06z" />
      <path fill="#edbba5" d="m61.18 94.845 2-3.464 2.598 1.5-2 3.464z" />
      <path fill="#f5b53f" d="m60.576 153.123 1.172-2.762 1.841.782-1.172 2.761z" />
      <path fill="#ee8367" d="m55.198 171.759-.726-2.91 1.94-.485.727 2.911z" />
      <path fill="#e67f3b" d="m49.806 104.62.224-3.188 2.125.15-.223 3.187z" />
    </g>
  </svg>
</template>
